@import "../../../node_modules/bootstrap/less/variables.less";

@orange: #f75d15;

#manager-menu {
	max-height: 90vh;

	.menu-item {
		border-left: 1px solid @navbar-default-border;

		&:hover {
			background-color: @gray-lighter;
		}
	}

	.scene-preview,
	.scene-play {
		border-left: none;
		border-right: 1px solid @navbar-default-border;

		&+.menu-item {
			border-left: none;
		}
	}

	.scene-play i {
		color: @orange;
	}

	@media only screen and (max-width: 768px) {
		.navbar-text {
			margin-left: 15px;
		}

		.scene-preview,
		.scene-play {
			display: none;
		}
	}
}

#manager-content {
	padding: 50px 15px 0;
}


.dropdown-menu.drop-left {
	left: auto !important;
}

@media (min-width: 992px) {
	#manager-content {
    max-height: 100vh; /* Viewport height */
    overflow: hidden; /* Hide overflow */
}
	.scrollable-column {
			max-height: 90vh; /* Adjust the height as needed */
			overflow-y: scroll;
			padding-right: 10px;
			padding-left: 10px;
	}
}

.modal-backdrop.am-fade {
	opacity: .5;
	transition: opacity .15s linear;
	&.ng-enter {
		opacity: 0;
		&.ng-enter-active {
			opacity: .5;
		}
	}
	&.ng-leave {
		opacity: .5;
		&.ng-leave-active {
			opacity: 0;
		}
	}
}

.datepicker.dropdown-menu {
	width: 250px;
	height: 270px;
	button {
		outline: none;
		border: 0px;
	}
	tbody {
		height: 180px;
	}
	tbody button {
		padding: 6px;
	}
	&.datepicker-mode-1, &.datepicker-mode-2 {
		tbody button {
			height: 65px;
		}
	}
}

.timepicker.dropdown-menu {
	padding: 0 4px;
	button {
		outline: none;
		border: 0px;
	}
	tbody button {
		padding: 6px;
	}
}
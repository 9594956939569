@font-face {
	font-family: '4000bold';
	src: url('assets/4000-bold.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: '4000heavyleft';
	src: url('assets/4000-heavy-left.otf');
}

@font-face {
	font-family: '4000heavyright';
	src: url('assets/4000-heavy-right.otf');
}

@font-face {
	font-family: '4000heavy';
	src: url('assets/4000-heavy.otf');
}

@font-face {
	font-family: '4000italic';
	src: url('assets/4000-italic.otf');
}

@font-face {
	font-family: '4000regular';
	src: url('assets/4000-regular.otf');
}

@font-face {
	font-family: 'ralewayregular';
	src: url('assets/raleway-regular-webfont.woff2') format('woff2'),
		url('assets/raleway-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}



.landscape {
	.scrolling-image-container {
		overflow: hidden;
		top: 0px;
		height: 100%;
		width: 177.77vh;
		position: absolute;
		z-index: 1;
	}

	.scrolling-image:before {
		background: url("assets/scroller_l.png") repeat-y;
		position: absolute;
		background-size: 100%;
		content: "";
		height: 6056px;
		width: 5.3%;
		top: 0px;
		left: 0px;
		z-index: 3;
		animation: slide_l 130s linear infinite;
	}

	.scrolling-image:after {
		z-index: 3;
	}

	@keyframes slide_l {
		0% {
			transform: translate(0);
		}

		100% {
			transform: translateY(-2271px);
		}
	}

	.scrolling-image-alt {
		position: relative;
		top: 100%;
		left: 0%;
		z-index: 3;
		transform: rotate(180deg);
	}
}

.portrait {
	.scrolling-image-container {
		overflow: hidden;
		top: 0px;
		height: 100%;
		width: 75vh;
		position: absolute;
		z-index: 1;
	}

	.scrolling-image {
		background: url("assets/scroller_p.png") repeat-x;
		background-size: auto 100%;
		top: 0px;
		left: 0px;
		height: 5.5%;
		width: 5076px;
		z-index: 3;
		animation: slide_p 130s linear infinite;
	}

	@keyframes slide_p {
		0% {
			transform: translate(0);
		}

		100% {
			transform: translate(-1692px);
		}
	}

	.scrolling-image-alt {
		position: relative;
		top: 89%;
		left: 0%;
		z-index: 3;
	}
}


player,
player-preview {
	position: absolute;
	height: 100vh;
	width: 100vw;
	overflow: hidden;

	background-color: #000000;
	color: #FFF;

	#stageWrapper {
		&.landscape {
			@portrait-wrapper-width: 177.77vh;

			@screen-fix: 0vh;
			padding: 0 @screen-fix 0 @screen-fix;

			@portrait-width: @portrait-wrapper-width - 2 * @screen-fix;
			width: @portrait-wrapper-width;
			min-height: 100vh;

			margin: 0 auto;

			#stage {
				position: relative;
				background-color: #000000;
				width: @portrait-width;
				height: 100vh;
			}
		}

		&.portrait {
			@portrait-wrapper-width: 77.79vh;

			@screen-fix: 0vh;
			padding: 0 @screen-fix 0 @screen-fix;

			@portrait-width: @portrait-wrapper-width - 2 * @screen-fix;
			width: 100%; // @portrait-wrapper-width;
			min-height: 100vh;

			margin: 0 auto;

			#stage {
				//position: relative;
				background-color: #000000;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%; // @portrait-width;
				height: 100vh;
			}
		}
	}
}

player {
	cursor: none;
}

.previewNotification {
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	background-color: rgba(0, 0, 0, 0.5);

	color: white;
	font-size: 1.5em;
	text-align: center;
}

file-media-image-player,
file-media-video-player {
	display: block;
	text-align: center;
}

service-announcement-player {
	display: block;
	width: 100%;
	height: 100%;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	text-transform: uppercase;

	color: white;

	.drawable {
		position: absolute;
	}

	.headline {
		font-family: '4000heavy';
		font-size: 9.8vh;
	}

	.body {
		font-family: '4000heavy';
		white-space: pre-line;
	}

	&.landscape {
		.drawable {
			top: 43.3%;
			right: 5.7%;
			bottom: 0;
			left: 5.7%;
		}

		font-size: 5.6vh;

		.body {
			margin-top: -2.0%;
			line-height: 94.2%;
			height: 37vh;
			overflow: hidden;
		}
	}

	&.portrait {
		
		width: 77.79vh;

		.drawable {
			top: 44.5%;
			//right: 4.5%;
			bottom: 0;
			//left: 4.5%;
			width: 77.79vh;
			
		}

		.headline {
			font-family: '4000heavy';
			font-size: 6.5vh;
		}

		font-size: 3.8vh;

		.body {
			margin-top: -3.3%;
			line-height: 119%;
			height: 41vh;
			overflow: hidden;
		}
	}
}

social-media-player {
	display: block;
	width: 100%;
	height: 100%;
	font-family: '4000heavy';

	background-size: cover;
	background-repeat: no-repeat;
	background-position: 100% 100%;

	color: white;

	.drawable {
		position: absolute;
		height: 100vh;
		width: inherit;
	}

	.frame {
		display: inline-flex;
		vertical-align: middle;

		img {
			margin: auto;
		}
	}

	.profile {

		.is-hashtag {
			font-family: '4000heavy';
		}

		.username {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			float: left;
		}

		.username--container {
			display: block;
			position: relative;
		}

		.post--container {
			display: block;
			position: relative;
			word-break: break-word;
			text-align: center;
		}

		img {
			display: inline;
			float: left;
		}
	}

	&.landscape {

		.profile {
			width: 48.5vh;
			margin-top: 14vh;
			margin-left: 9.4vh;
			float: left;


			.username--container {
				position: absolute;
				top: 21.1vh;
				left: 9.4vh;

				.canopy-img {
					//display: none;
				}
			}

			.username {
				max-width: 83vh;
				margin-bottom: 0;
				font-size: 4.8vh;
				margin-left: -31.4vh;
				margin-top: 3.6vh;
				text-transform: uppercase;
			}

			.post--container {
				width: 58.4vh;
				margin-top: 27.9vh;
				font-size: 4.5vh;
				line-height: 1.22;
				height: 56vh;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 10;
				-webkit-box-orient: vertical;
				text-align: left;
				text-transform: uppercase;
				left: -1.7vh
			}

			img {
				height: 10vh;
				margin-top: -9vh;
				margin-left: 13vh;
			}
		}

		.frame {
			margin-top: 9.9vh;
			margin-left: 24.0vh;
			width: 80vh;
			height: 80vh;

			img {
				max-width: 100vh;
				max-height: 100vh;
			}

			video {
				margin: 0 auto;
			}
		}
	}

	&.portrait {

		width: 77.79vh;

		.profile {
			.username--container {
				position: absolute;
				bottom: 21.1vh;
				left: 36.9vh;

				.canopy-img {
					//display: none;
				}
			}

			.username {
				font-size: 3.2vh;
				text-transform: uppercase;
			}

			.post--container {
				position: absolute;
				top: 81.8vh;
				left: 8.9vh;
				right: 8.9vh;
				height: 16.1vh;
				font-size: 3.3vh;
				line-height: 114%;
				//width: 54vh;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-transform: uppercase;
				text-align: left;
			}

			img {
				height: 6.6vh;
				margin-top: -0.3vh;
				margin-left: -44.3vh;
				float: none;
			}
		}


		.frame {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8.5vh;
			margin-left: 9.5vh;
			width: 59vh;
			height: 59vh;
			overflow: hidden;

			img {
				width: 71vh;
				height: 71vh;
			}
		}
	}
}

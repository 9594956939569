#manager-menu {
  max-height: 90vh;
}
#manager-menu .menu-item {
  border-left: 1px solid #e7e7e7;
}
#manager-menu .menu-item:hover {
  background-color: #eeeeee;
}
#manager-menu .scene-preview,
#manager-menu .scene-play {
  border-left: none;
  border-right: 1px solid #e7e7e7;
}
#manager-menu .scene-preview + .menu-item,
#manager-menu .scene-play + .menu-item {
  border-left: none;
}
#manager-menu .scene-play i {
  color: #f75d15;
}
@media only screen and (max-width: 768px) {
  #manager-menu .navbar-text {
    margin-left: 15px;
  }
  #manager-menu .scene-preview,
  #manager-menu .scene-play {
    display: none;
  }
}
#manager-content {
  padding: 50px 15px 0;
}
.dropdown-menu.drop-left {
  left: auto !important;
}
@media (min-width: 992px) {
  #manager-content {
    max-height: 100vh;
    /* Viewport height */
    overflow: hidden;
    /* Hide overflow */
  }
  .scrollable-column {
    max-height: 90vh;
    /* Adjust the height as needed */
    overflow-y: scroll;
    padding-right: 10px;
    padding-left: 10px;
  }
}

// Modded button from Bootstrap 3

.button-discreet(@color; @background; @border; @color2; @background2; @border2) {
	color: @color;
	background-color: @background;
	border-color: @border;

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: @color2;
		background-color: darken(@background2, 10%);
		border-color: darken(@border2, 12%);
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: @background2;
			border-color: @border2;
		}
	}

	&[disabled] {
		//TODO: Fix properly (disabled button is color2 all the time. Should only be on hover/focus/active)
		color: @color;
		background-color: @background;
		border-color: @border;
	}

	.badge {
		color: @background;
		background-color: @color;
	}
}

.button-discreet-light(@color; @background; @border; @color2; @background2; @border2) {
	.button-discreet(@color; @background; @border; @color2; @background2; @border2);

	&:hover {
		background-color: @background2;
		border-color: @border2;
	}
}

.btn-primary-discreet {
	.button-discreet(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-primary-color; @btn-primary-bg; @btn-primary-border);
}

.btn-success-discreet {
	.button-discreet(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-success-color; @btn-success-bg; @btn-success-border);
}

.btn-info-discreet {
	.button-discreet(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-info-color; @btn-info-bg; @btn-info-border);
}

.btn-warning-discreet {
	.button-discreet(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-warning-color; @btn-warning-bg; @btn-warning-border);
}

.btn-danger-discreet {
	.button-discreet(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-danger-color; @btn-danger-bg; @btn-danger-border);
}